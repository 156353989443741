const routes = [
    {
        path: "/",
        redirect: { name: "Index" },
        name: "root",
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "/goals/goal/create",
                component: () => import("./views/Goal/Create.vue"),
                name: "GoalCreate",
            },
            {
                path: "/departments",
                components: {
                    default: () => import("./views/Org.vue"),
                    "title-actions": () => import("./components/ActionsButton.vue"),
                },
                name: "departments",
            },
            {
                path: "/positions",
                components: {
                    default: () => import("./views/Positions.vue"),
                    "title-actions": () => import("./components/ActionsButton.vue"),
                },
                name: "Positions",
            },
            {
                path: "/competences",
                components: {
                    default: () => import("./views/Competences.vue"),
                    "title-actions": () => import("./components/ActionsButton.vue"),
                },
                name: "Competences",
            },
            {
                path: "/competences/bind",
                components: {
                    default: () => import("./views/CompetencesBind.vue"),
                    "title-actions": () => import("./components/ActionsButton.vue"),
                },
                name: "CompetencesBind",
            },
            {
                path: "/competences/indicator/:id?",
                component: () => import("./views/CompetencesEdit.vue"),
                name: "CompetencesEdit",
            },
            {
                path: "/goals/goal/view/:goal_id",
                component: () => import("./views/Goal/View.vue"),
                name: "GoalView",
                props: true,
            },
            {
                path: "/goals/goal/user/:user_id/list",
                component: () => import("./views/Goal/UserList.vue"),
                name: "GoalUserList",
                props: true,
            },
            {
                path: "/goals/my-goals",
                component: () => import("./views/Goal/UserList.vue"),
                name: "MyGoalsList",
                props: true,
            },
            /*{
                path: "/goals/goal/subordinates",
                component: () => import("./views/Goal/AllUsersGoals.vue"),
                name: "GoalSubordinates",
            },*/
            {
                path: "/goals/goal/all",
                component: () => import("./views/Goal/AllUsersGoals.vue"),
                name: "AllUsersGoals",
            },
            {
                path: "/",
                component: () => import("./views/Index.vue"),
                name: "Index",
            },
            {
                path: "/user/:id",
                component: () => import("./views/UserProfile.vue"),
                name: "UserProfile",
                props: true,
            },
            {
                path: "/team/",
                component: () => import("./views/TeamIndex.vue"),
                name: "TeamIndex",
            },
            {
                path: "/invite/",
                component: () => import("./views/InviteUsers/Tabs.vue"),
                name: "InviteUsers",
            },
            {
                path: "/manage-users/",
                components: {
                    default: () => import("./views/ManageUsers.vue"),
                    "title-actions": () => import("./components/ActionsButton.vue"),
                },
                component: () => import("./views/ManageUsers"),
                name: "ManageUsers",
            },
            {
                path: "/subscription/",
                component: () => import("./views/Subscription"),
                name: "Subscription",
            },
            {
                path: "/billing/processing",
                component: () => import("./views/Billing/SubscriptionProcessing"),
                name: "SubscriptionProcessing",
            },
        ],
    },
];
export default routes;
