import Vue from "vue";
import { default as HiHub, options } from "vue-hihub-ui-core";
import "@hihub/vue-hihub-departments";
import "@hihub/vue-hihub-competences";
import "@hihub/vue-hihub-positions";

Vue.use(HiHub);

/* Init service routers */
import routes from "./routes";
routes.forEach(function(routeItem) {
    options.router.addRoute(routeItem);
});

/* Import locales */
import locales from "./i18n";
for (let locale in locales) {
    options.i18n.mergeLocaleMessage(locale, locales[locale]);
}
import competenceLocales from "@hihub/vue-hihub-competences/src/langs";
for (let locale in competenceLocales) {
    options.i18n.mergeLocaleMessage(locale, competenceLocales[locale]);
}
import positionLocales from "@hihub/vue-hihub-positions/src/langs";
for (let locale in positionLocales) {
    options.i18n.mergeLocaleMessage(locale, positionLocales[locale]);
}
import departmentsLocales from "@hihub/vue-hihub-departments/src/i18n";
for (let locale in departmentsLocales) {
    options.i18n.mergeLocaleMessage(locale, departmentsLocales[locale]);
}

/* Import service menu */
import menu from "./menu";
import { SET_MENU } from "@/core/services/store/menu.module";
options.store.dispatch(SET_MENU, { code: "aside", menu });

/* Register vuex storage */
import modules from "./store/index";

modules.forEach(module => {
    options.store.registerModule(module.name, module.module);
});

/* Set api requests */
import ApiRequests from "./Api";
import ApiRequestsDepartments from "@hihub/vue-hihub-departments/src/Api";
import ApiRequestsPositions from "@hihub/vue-hihub-positions/src/Api";
import ApiRequestsCompetences from "@hihub/vue-hihub-competences/src/Api";

options.ApiService.mergeRequests(ApiRequests);
options.ApiService.mergeRequests(ApiRequestsDepartments);
options.ApiService.mergeRequests(ApiRequestsPositions);
options.ApiService.mergeRequests(ApiRequestsCompetences);

/* Override layout_config (left menu) */
import AppConfig from "./services/AppConfig";
import { OVERRIDE_INITIAL_CONFIG } from "@/core/services/store/config.module";
options.store.dispatch(OVERRIDE_INITIAL_CONFIG, AppConfig);

new Vue({ ...options }).$mount("#app");
