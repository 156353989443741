import Vue from "vue";

const teams = {
    namespaced: true,
    state: {
        current: {
            id: 0
        },
        teams: {}
    },
    getters: {
        UserActiveTeam: (state, getters) => getters.Team(state.current.id),
        UserTeams: state => state.teams,
        Team: state => id => state.teams[id]
    },
    mutations: {
        LOAD_TEAM(state, payload) {
            if (payload.id) {
                Vue.set(state.teams, payload.id, Object.assign({}, payload, { modified: false }));
            }
        },
        MARK_ACTIVE(state, payload) {
            state.current.id = payload;
        }
    },
    actions: {
        load(context) {
            this.$app.$api.teams.user_teams().then(response => {
                for (let i = 0; i < response.data.length; i++) {
                    context.commit("LOAD_TEAM", response.data[i]);
                }
            });
        },
        get(context, payload) {
            this.$app.$api.teams.user_team(payload).then(response => {
                context.commit("LOAD_TEAM", response.data);
            });
        },
        update_name(context, payload) {
            context.dispatch("update", { id: payload.id, data: { name: payload.name } });
        },
        update(context, payload) {
            this.$app.$api.teams.update_team(payload.id, payload.data).then(response => {
                context.dispatch("get", payload.id);
            });
        },
        mark_active(context, payload) {
            if (payload) context.commit("MARK_ACTIVE", payload);
        },
        set_active(context, payload) {
            this.$app.$api.teams.set_active_team(payload).then(response => {
                if (response.data) {
                    context.commit("MARK_ACTIVE", payload);
                }
            });
        }
    }
};

export default teams;
