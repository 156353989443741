import store from "@/core/services/store";
import router from "@/router";

const goalRoutes = [
    "GoalCreate",
    "GoalView",
    "GoalUserList",
    "MyGoalsList",
    "GoalSubordinates",
    "AllUsersGoals"
];

export default {
    submenu: {
        goals: {
            //type: "section",
            icon: "flaticon-star",
            text_code: "goal.menu.index",
            text: {
                code: "goal.menu.index",
                default: "Goals menu"
            },
            active: function() {
                return goalRoutes.includes(router.currentRoute?.name);
            },
            submenu: {
                create: {
                    router_link: "GoalCreate",
                    active: true,
                    icon: "flaticon2-plus",
                    text_code: "goal.menu.create",
                    text: {
                        code: "goal.menu.create",
                        default: "Goals create"
                    }
                },
                list: {
                    router_link: "MyGoalsList",
                    icon: "flaticon-user",
                    text_code: "goal.menu.user_list",
                    active: true,
                    text: {
                        code: "goal.menu.user_list",
                        default: "My goals"
                    }
                },
                /*subordinates: {
                    router_link: "GoalSubordinates",
                    icon: "flaticon-users",
                    text_code: "goal.menu.user_list",
                    active: function() {
                        const user = store.getters.currentUser;
                        return user ? user.is_head : false;
                    },
                    text: {
                        code: "goal.menu.subordinates",
                        default: "Goals of Subordinates"
                    }
                },*/
                all_users_dashboard: {
                    router_link: "AllUsersGoals",
                    icon: "flaticon-users",
                    text: {
                        code: "goal.menu.all_users_dashboard",
                        default: "All Users Goals"
                    },
                    active: function() {
                        const user = store.getters.currentUser;
                        return user ? user.is_admin : false;
                    }
                }
            }
        },
        team: {
            router_link: "TeamIndex",
            icon: "flaticon-network",
            text_code: "menu.team.index",
            active: () => false, //process.env.VUE_APP_ENV === "testing"
        },
        bindCompetences: {
            router_link: "CompetencesBind",
            icon: "flaticon2-user-1",
            text: {
                code: "hihub_competences.bind_competences",
                default: "Bind competences"
            },
            active: function() {
                const user = store.getters.currentUser;
                const routeName = router.currentRoute.name;
                return (routeName.includes('Competences') && user ? user.is_admin : false);
            }
        }
    }
};
