import Vue from "vue";

const manage_users = {
    namespaced: true,
    state: {
        usersList: {
            data: {},
            pagination: {},
        },
        modals: {
            add_users: false,
            delete_users: false,
            manage_roles: false,
            manage_status: false,
            manage_departments: false,
            manage_positions: false,
            block_all_users: false,
            sync: false,
        },
        dispatchCounter: 0,
        lastDispatchId: 0,
    },
    getters: {
        usersList: (state) => state.usersList.data,
        pagination: (state) => state.usersList.pagination,
        modalShow: (state) => (modal) => state.modals[modal],
        getDispatchCounter: (state) => state.dispatchCounter,
    },
    mutations: {
        UPDATE_DISPATCH_COUNTER(state, data){
            let val = Number(data);
            let newVal = state.dispatchCounter + val;
            Vue.set(state, "dispatchCounter", newVal);
        },
        SET_USERS_LIST(state, { data, pagination }) {
            Vue.set(state.usersList, "data", data);
            Vue.set(state.usersList, "pagination", pagination);
        },
        SET_MODAL_SHOW(state, { modal, show = true }) {
            Vue.set(state.modals, modal, show);
        },
    },
    actions: {
        loadUsers({commit, state}, payload) {
            commit("UPDATE_DISPATCH_COUNTER", 1);
            state.lastDispatchId++;
            let dispatchId = state.lastDispatchId;
            return this.$app.$api.users
                .getManageList(payload)
                .then((response) => {
                    if (state.lastDispatchId == dispatchId) {
                        let pagination = {};
                        for (let index in response.data.meta) {
                            if (index === "current_page" || index === "per_page" || index === "total")
                                pagination[index] = response.data.meta[index];
                        }
                        const result = response.data.data ?? [];
                        commit("SET_USERS_LIST", { data: result, pagination: pagination });
                    }
                }).finally(() => {
                    commit("UPDATE_DISPATCH_COUNTER", -1);
                });
        },
    },
};

export default manage_users;
