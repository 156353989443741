import Vue from "vue";
import Api from "../Api";

const subordinates = {
    namespaced: true,
    state: {
        users: {}
    },
    getters: {
        AllUsers: state => state.users,
        User: state => user_id => {
            return false;
        },
        UserCompetence: state => (user_id, competence_id) => {
            if (
                state.users[user_id] !== undefined &&
                state.users[user_id][competence_id] !== undefined
            )
                return state.users[user_id][competence_id];
            return false;
        }
    },
    mutations: {
        ADD_USER(state, payload) {
            if (payload.user_id) {
                Vue.set(state.users, payload.user_id, {});
            }
        },

        ADD_OR_UPDATE_COMPETENCE(state, payload) {
            if (payload.user_id && payload.competence_id && payload.value !== undefined) {
                if (state.users[payload.user_id] === undefined) {
                    Vue.set(state.users, payload.user_id, {});
                }
                if (state.users[payload.user_id][payload.competence_id] !== undefined) {
                    payload.all_goals = 0;
                    payload.completed_goals = 0;
                }
                Vue.set(state.users[payload.user_id], payload.competence_id, payload.value);

                let all_goals = 0;
                if (state.users[payload.user_id].all_goals !== undefined) {
                    all_goals = state.users[payload.user_id].all_goals;
                }
                state.users[payload.user_id].all_goals = payload.all_goals + all_goals;

                let completed_goals = 0;
                if (state.users[payload.user_id].completed_goals !== undefined) {
                    completed_goals = state.users[payload.user_id].completed_goals;
                }
                state.users[payload.user_id].completed_goals =
                    payload.completed_goals + completed_goals;
            }
        },

        DELETE_COMPETENCE(state, payload) {
            if (payload.user_id && payload.competence_id) {
                if (state.users[payload.user_id][payload.competence_id])
                    delete state.users[payload.user_id][payload.competence_id];
            }
        }
    },
    actions: {
        loadUser(context, payload) {
            context.commit("ADD_USER", payload);
        },
        updateCompetence(context, payload) {
            context.commit("ADD_OR_UPDATE_COMPETENCE", payload);
        },
        deleteCompetence(context, payload) {
            context.commit("DELETE_COMPETENCE", payload);
        }
    }
};

export default subordinates;
