export default class Api {}

Api.sync = {
    start_global_sync: ({ client }, data) => client.post("/api/sync", data),
};

Api.teams = {
    user_teams: ({ client }) => client.get("/api/workspaces/all"),
    user_team: ({ client }, team_id) => client.get("/api/teams/" + team_id),
    update_team: ({ client }, team_id, data) => client.patch("/api/teams/" + team_id, data),
    set_active_team: ({ client }, team_id) => client.patch("/api/teams/" + team_id + "/set_active"),
    setTariff: ({ client }, tariff_id) => client.patch("/api/tariffs/teams/tariff/" + tariff_id)
};

Api.competences = {
    user_list: ({ client }, user_id) => client.get("/api/goal/competence/user/" + user_id)
};
Api.users = {
    bindDepartments: ({ client }, data) => client.post("/api/users/bind-departments", data),
    bindPositions: ({ client }, data) => client.post("/api/users/bind-positions", data),
    generateNewPassword: ({ client }, data) => client.post("/api/users/notify/password", data),
    update: ({ client }, user_id, data) => client.post("/api/user/" + user_id, data),
    uploadAvatar: ({ client }, user_id, avatar_file_data) =>
        client.post("/api/user/" + user_id + "/avatar", avatar_file_data),
    delete: ({ client }, ids) => client.delete("/api/users/delete", { data: { users: ids } }),
};

Api.departments = {
    subordinates_search: ({ client }, query) =>
        client.get("/api/departments/subordinates/search?query=" + query),
    getTree: ({ client }) => client.get("/api/departments")
};

Api.positions = {
    getAll: ({ client }) => client.get("/api/positions")
};

Api.goals = {
    create: ({ client }, data) => client.post("/api/goal/", data),
    load_file: ({ client }, id, data) => client.post("/api/goal/" + id + "/file/", data),
    user_list: ({ client }, user_id) => client.get("/api/goal/user/" + user_id + "/list"),
    users_list: ({ client }, data) => client.post("/api/goal/users/list", data),
    goal: ({ client }, goal_id) => client.get("/api/goal/goal/" + goal_id),
    destroy: ({ client }, goal_id) => client.delete("/api/goal/" + goal_id),

    close: ({ client }, goal_id) => client.put("/api/goal/goal/" + goal_id + "/close"),
    comment: ({ client }, goal_id, data) =>
        client.post("/api/goal/goal/" + goal_id + "/comment", data),
    load_excel: ({ client }, data) =>
        client.post("/api/goal/excel/", data, { responseType: "blob" })
};

Api.billing = {
    tariffs: {
        index: ({ client }) => client.get("/api/billing/tariffs"),
        current: ({ client }) => client.get("/api/billing/current-tariff")
    },
    restrictions: {
        index: ({ client }) => client.get("/api/billing/restrictions")
    },
    plans: {
        index: ({ client }) => client.get("/api/billing/plans")
    },
    price: {
        index: ({ client }) => client.get("/api/billing/price")
    },
    search: ({ client }, type, query) => client.get("/api/billing/form/search?type=" + type + "&query=" + query),
    invoice: {
        create: ({ client }, data) => client.post("/api/billing/invoice", data),
        download: ({client}, id) => client.post("/api/billing/invoice/" + id + "/download", {},
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }
        ),
        send_info: ({ client }, data) => client.post("/api/billing/invoice/send-info", data)
    }
};
