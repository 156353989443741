import Vue from "vue";

const billing = {
    namespaced: true,
    state: {
        restrictions: [],
        restrictionsLoaded: false,
        plans: [],
        plansLoaded: false,
        prices: [],
        pricesLoaded: false,
        order: {},
        currency: '',
        invoice: {},
    },
    getters: {
        restrictions: state => state.restrictions,
        restrictionsLoaded: state => state.restrictionsLoaded,
        plans: state => state.plans,
        plansLoaded: state => state.plansLoaded,
        prices: state => state.prices,
        pricesLoaded: state => state.pricesLoaded,
        order: state => state.order,
        currency: state => state.currency,
        invoice: state => state.invoice
    },
    mutations: {
        SET_RESTRICTIONS(state, restrictions) {
            Vue.set(state, "restrictions", restrictions);
        },
        SET_PLANS(state, plans) {
            Vue.set(state, "plans", plans);
        },
        SET_PRICES(state, prices){
            Vue.set(state, "prices", prices);
        },
        UPDATE_ORDER(state, newValues) {
            for(let key in newValues) {
                if (newValues.hasOwnProperty(key)) {
                    Vue.set(state.order, key, newValues[key]);
                }
            }
        },
        SET_CURRENCY(state, value) {
            state.currency = value;
        },
        ADD_INVOICE(state, invoice){
            state.invoice = invoice;
        },
    },
    actions: {
        loadRestrictions(context) {
            if (!context.state.restrictionsLoaded) {
                this.$app.$api.billing.restrictions.index().then(response => {
                    context.state.restrictionsLoaded = true;
                    context.commit("SET_RESTRICTIONS", response.data.data);
                });
            }
        },
        loadPlans(context){
            return new Promise((resolve, reject) => {
                if (!context.state.plansLoaded) {
                    this.$app.$api.billing.plans.index().then(response => {
                        context.state.plansLoaded = true;
                        context.commit("SET_PLANS", response.data.data);
                        resolve();
                    }).catch(() => {
                        reject();
                    });
                }
                else {
                    resolve();
                }
            });
        },
        loadPrices(context) {
            if (!context.state.pricesLoaded) {
                this.$app.$api.billing.price.index().then(response => {
                    context.state.pricesLoaded = true;
                    context.commit("SET_PRICES", response.data.data);
                });
            }
        },
        createInvoice(context) {
            const request = this.$app.$api.billing.invoice.create(context.state.order);
            request.then(response => {
                context.commit("ADD_INVOICE", response.data);
            });
            return request;
        },
        sendInvoiceInfo(context, info) {
            this.$app.$api.billing.invoice.send_info(info);
        },
        setCurrency({ commit }, currency) {
            commit("SET_CURRENCY", currency);
            commit("UPDATE_ORDER", { currency });
        },
        downloadInvoice(context, id) {
            return this.$app.$api.billing.invoice.download(id);
        }
    }
};

export default billing;
