import teams from "./teams.js";
import billing from "./billing.js";
import subordinates from "./subordinates.js";
import manage_users from "./manage_users.js";
import competence from "@hihub/vue-hihub-competences/src/store/competence";

export default [
    {
        name: "teams",
        module: teams
    },
    {
        name: "subordinates",
        module: subordinates
    },
    {
        name: "manage_users",
        module: manage_users
    },
    {
        name: "competence",
        module: competence
    },
    {
        name: "billing",
        module: billing
    },
];
