import Vue from "vue";

function loadLocaleMessages() {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        const path = [...key.matchAll(/([A-Za-z0-9-_]+)\//g)].map(value => value[1]).reverse();

        if (matched && matched.length > 1) {
            const locale = matched[1];
            let tree = locales(key);
            path.forEach(dir => {
                if (dir.length > 0) tree = { [dir]: tree };
            });
            if (!messages[locale]) messages[locale] = {};
            messages[locale] = mergeDeep(messages[locale], tree);
        }
    });

    return messages;
}

function mergeDeep(target, source) {
    const isObject = obj => obj && typeof obj === "object";

    if (!isObject(target) || !isObject(source)) {
        return source;
    }

    Object.keys(source).forEach(key => {
        const targetValue = target[key];
        const sourceValue = source[key];

        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
            target[key] = targetValue.concat(sourceValue);
        } else if (isObject(targetValue) && isObject(sourceValue)) {
            target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
        } else {
            target[key] = sourceValue;
        }
    });

    return target;
}

export default loadLocaleMessages();
